<template>
  <div class="portfolio">
    <v-dialog
      v-model="dialog"
      :overlay="false"
      max-width="1000"
      max-height="1000"
      transition="dialog-transition"
    >
      <v-img :src="activeImage"></v-img>
    </v-dialog>

    <v-parallax ma-0 height="450" src="@/assets/deerrenderfinal.png">
      <h1 class="display-3 glowtext text-center">PORTFOLIO</h1>
    </v-parallax>

    <v-container fluid grid-list-xs>
      <v-container grid-list-xs>
        <v-container
          class="borderrrr elevation-5"
          style="border-top-right-radius: 5px; border-top-left-radius: 5px"
        >
          <h1 class="display-1 glowtext">ENVIORMENTS</h1>
        </v-container>
        <v-container class="mt-4 elevation-5">


          <v-card elevation="0" class="mx-auto" color="transparent">
            <v-row>
              <v-col
                class="d-flex child-flex justify-center"
                v-for="item in image"
                :key="item"
              >
                <v-img
                  @click="openImage2(item)"
                  max-width="400"
                  class="mt-2 roundedimg elevation-10"
                  :src="item"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      dialog: false,
      activeImage: null,
      image: [
        require("@/assets/Nolz.jpg"),
        require("@/assets/deerrenderfinal.png"),
        require("@/assets/giant.png"),
        require("@/assets/japan.png"),
        require("@/assets/mars.png"),
        require("@/assets/cardboard.png"),
      ],
    };
  },
  methods: {
    openImage(src) {
      this.activeImage = this.image[src];
      this.dialog = true;
    },
    openImage2(img) {
      this.activeImage = img;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss">
.roundedimg {
  border-radius: 1px;
  border: rgb(255, 255, 255) 1px inset;
  transition-duration: 0.1s;
  cursor: pointer;
}
.roundedimg:hover {
  border: rgb(255, 187, 0) 1px inset;
  border-radius: 5px;
}
.borderrrr {
  border: orange 1px solid;

  background-color: rgba(255, 237, 177, 0.048);
}
.glowtext {
  text-shadow: rgba(255, 255, 255, 0.5) 0px 0px 10px;
}
</style>
